import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

export const formatDatetime = (dtSource, hasTime = true, hasDate = true) => {
  if (!dtSource) return null

  return format(
    typeof dtSource === 'string' ? parseISO(dtSource + 'Z') : dtSource + 'Z',
    `${hasDate ? 'dd/MM/yyyy' : ''}${hasDate && hasTime ? ' ' : ''}${
      hasTime ? 'HH:mm' : ''
    }`
  )

  // let [date, time] = new Date(dtSource + 'Z').toLocaleString().split(' ')
  // const [d, m, y] = date.split('/')
  // let dateSTR = `${d.length === 1 ? '0' : ''}${d}/${
  //   m.length === 1 ? '0' : ''
  // }${m}/${y}`
  // if (hasTime && time) {
  //   const [h, m] = time.split(':')
  //   dateSTR += ` ${h}:${m}`
  // }

  // let [date, time] = dtSource.split('T')
  // date = date.split('-')
  // let dateSTR = date[2] + '/' + date[1] + '/' + date[0]
  // if (hasTime) {
  //   time = time.split(':')
  //   dateSTR += ' ' + time[0] + ':' + time[1]
  // }

  // return dateSTR

  // const date = new Date(dtSource + 'Z')
  // let options = {}
  // options.timeZone = 'UTC'
  // options.year = 'numeric'
  // options.month = '2-digit'
  // options.day = '2-digit'
  // if (hasTime) {
  // 	options.hour = '2-digit'
  // 	options.minute = '2-digit'
  // }
  // return date.toLocaleString('es-ES', options)
}

export const formatNumber = (number = 0, removeDecimals = false) => {
  const processedNumber = removeDecimals ? mathRound(number) : number
  return new Intl.NumberFormat('es-ES').format(processedNumber)
}

export const formatCurrency = (amount = 0, removeDecimals = false) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    ...(removeDecimals
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : {}),
  }).format(amount)
}

export const formatPercentage = (percentage = 0, removeZero = false, label = '', prefix='') => {
  return removeZero ? (percentage > 0 ? `${prefix} ${percentage}% ${label}`.trim()  : '') : `${prefix} ${percentage}% ${label}`.trim()
}

export const formatPhone = (phone, separator = ' ') => {
  return phone.replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

export const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((t, k) => {
    const item = obj[k]
    const element =
      item && typeof item === 'object'
        ? flattenObject(item, `${prefix}${k}/`)
        : [`${prefix}${k}: ${item}`]
    return [...t, ...element]
  }, [])

export const toLocaleISOString = dt => {
  const dateFields = dt
    .toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')
  const time = dt.toLocaleTimeString('en-US', { hour12: false })
  return `${dateFields[2]}-${dateFields[1]}-${dateFields[0]}T${time}`
}

export const dateDaysDiff = (firstDate, secondDate) => {
  return Math.ceil(
    (secondDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24)
  )
}

export const objectValues = obj => Object.keys(obj).map(v => obj[v])

export const mathRound = num => Math.sign(num) * Math.round(Math.abs(num))
