import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { Input, Loader } from 'semantic-ui-react'
import DebounceInput from 'react-debounce-input'
import styled from 'react-emotion'

import { withUserContext } from './Context'

import {
  ROUTE_CLIENT_VIEW,
  ROUTE_SERVICE_VIEW,
  ROUTE_INVOICE_VIEW,
  ROUTE_ORDER_VIEW,
} from '../routes'
import { APIBASEURL, APIREQUESTOPTIONS } from '../globals'

const CATEGORYNAMES = {
  subjects: { name: 'Clientes', route: ROUTE_CLIENT_VIEW },
  billing_profiles: { name: 'Razones sociales', route: ROUTE_CLIENT_VIEW },
  contacts: { name: 'Contactos', route: ROUTE_CLIENT_VIEW },
  services: { name: 'Servicios', route: ROUTE_SERVICE_VIEW },
  bills: { name: 'Facturas', route: ROUTE_INVOICE_VIEW },
  orders: { name: 'Presupuestos', route: ROUTE_ORDER_VIEW },
}

// STYLED COMPONENTS.
const SearchInput = styled('div')`
  position: relative;
  margin: 0 2rem;
`
const SearchResults = styled('div')`
  position: absolute;
  background: #ffffff;
  border: 1px solid #d4d4d5;
  border-radius: 0.4rem !important;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
  z-index: 2000;
  margin-top: 0.5rem;
  width: 100%;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
`
const SearchResultsSection = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  border-bottom: 1px solid #dddddd;
  &:last-of-type {
    border-bottom: none;
  }
  & .results-section-name {
    flex: 0 1 auto;
    width: 130px;
    background: #f3f4f5;
    padding: 0.5rem 1rem;
    font-weight: 700;
    color: #888888;
  }
  & .results-section-results {
    flex: 1 1 auto;
  }
`
const SearchResult = styled('div')`
  display: block;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #dddddd;
  background: ${props => (props.focused ? '#eeeeee' : '#ffffff')};
  &:last-of-type {
    border-bottom: none;
  }
  :hover {
    background: #eeeeee;
    cursor: pointer;
  }
  & .result-title {
    font-size: 1em;
    font-weight: 700;
  }
  & .result-description {
    font-size: 0.95em;
    color: #888888;
  }
`

const CancelToken = axios.CancelToken
let cancel = () => null

class GlobalSearch extends Component {
  state = {
    status: 'no-search',
    search: '',
    rawSearchResults: [],
    focusedResultIndex: null,
  }

  componentDidMount() {
    document.getElementById('zges-global-search').focus()
  }

  componentDidUpdate(prevProps, prevState) {
    document.getElementById('zges-global-search').focus()
  }

  resetState = () => {
    this.setState({
      status: 'no-search',
      search: '',
      rawSearchResults: [],
      focusedResultIndex: null,
    })
  }

  handleSearch = e => {
    // Cancel any previous axios request.
    cancel('Búsqueda detenida')

    const search = e.target.value

    if (search.length > 2) this.getSearch(search)
    else
      this.setState({
        status: 'no-search',
        search,
        rawSearchResults: [],
      })
  }

  handleKeys = ({ keyCode }) => {
    // Cancel any previous axios request.
    cancel('Búsqueda detenida')

    // ESC.
    if (keyCode === 27) {
      this.resetState()
      return
    }

    const { status, rawSearchResults, focusedResultIndex } = this.state
    const nResults = rawSearchResults.length
    if (status === 'results-found') {
      // Cursor DOWN.
      if (keyCode === 40) {
        this.setState(prevState => ({
          focusedResultIndex:
            prevState.focusedResultIndex === null
              ? 0
              : (prevState.focusedResultIndex + 1) % nResults,
        }))
        return
      }
      // Cursor UP.
      else if (keyCode === 38) {
        this.setState(prevState => ({
          focusedResultIndex:
            prevState.focusedResultIndex === null
              ? nResults - 1
              : (prevState.focusedResultIndex + nResults - 1) % nResults,
        }))
        return
      }
      // ENTER.
      else if (
        keyCode === 13 &&
        focusedResultIndex !== null &&
        focusedResultIndex >= 0
      ) {
        const { id, model } = rawSearchResults[focusedResultIndex]
        this.props.history.push(CATEGORYNAMES[model].route.replace(':id', id))
        this.resetState()
      }
    } else {
      this.setState({ status: 'no-search' })
    }
  }

  handleSelection = link => {
    this.resetState()
    if (link) this.props.history.push(link)
  }

  getSearch = search => {
    this.setState({ status: 'searching', search })

    axios({
      method: 'get',
      url: `${APIBASEURL}/admin/searchers?search=${search}`,
      ...APIREQUESTOPTIONS,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c
      }),
    })
      .then(response => {
        const data = response.data.data
        this.setState({
          status: data.length > 0 ? 'results-found' : 'results-not-found',
          rawSearchResults: data.length > 0 ? data : [],
        })
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          this.resetState()
          console.error(error)
          this.props.user.logoutUser()
        }
      })
  }

  formatResults = results =>
    results.reduce((t, v, i) => {
      if (!CATEGORYNAMES[v.model]) {
        console.error(`Tipo de respuesta desconocida: ${v.model}`)
        return t
      }

      if (!t[v.model])
        t[v.model] = { name: CATEGORYNAMES[v.model].name, results: [] }

      t[v.model]['results'].push(
        <SearchResult
          key={v.title + i}
          focused={this.state.focusedResultIndex === i}
          onClick={() =>
            this.handleSelection(
              CATEGORYNAMES[v.model].route.replace(':id', v.id)
            )
          }
        >
          <div className="result-title">{v.title}</div>
          <div className="result-description">
            {['subjects', 'contacts'].includes(v.model)
              ? v.data
                  .split('|')
                  .filter(w => w)
                  .join(' | ')
              : v.data}
          </div>
        </SearchResult>
      )

      return t
    }, {})

  render() {
    const { status, search, rawSearchResults } = this.state
    const formattedSearchResults = this.formatResults(rawSearchResults)

    return (
      <SearchInput>
        <DebounceInput
          id="zges-global-search"
          autoComplete="off"
          fluid
          size="large"
          loading={status === 'searching'}
          element={Input}
          minLength={1}
          debounceTimeout={500}
          placeholder="Buscar..."
          icon="search"
          name="search"
          value={search}
          onKeyUp={this.handleKeys}
          onChange={this.handleSearch}
        />
        {status === 'searching' && (
          <SearchResults>
            <Loader
              size="small"
              active
              inline="centered"
              style={{ marginTop: '2rem', marginBottom: '2rem' }}
            />
          </SearchResults>
        )}
        {status === 'results-not-found' && (
          <SearchResults>
            <div style={{ padding: '1rem' }}>Sin resultados</div>
          </SearchResults>
        )}
        {status === 'results-found' && (
          <SearchResults>
            {Object.keys(formattedSearchResults).map(k => (
              <SearchResultsSection key={k}>
                <div className="results-section-name">
                  {formattedSearchResults[k].name}
                </div>
                <div className="results-section-results">
                  {formattedSearchResults[k].results}
                </div>
              </SearchResultsSection>
            ))}
          </SearchResults>
        )}
      </SearchInput>
    )
  }
}

export default withUserContext(withRouter(GlobalSearch))
