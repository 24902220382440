import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Form, Message, Icon, Button, Segment } from 'semantic-ui-react'

/**
 * Shows a form with login and password inputs, makes an API request,
 * handle error and returns when success. Also allow password reminder form.
 *
 * @class LoginForm
 * @extends {PureComponent}
 */
export class LoginForm extends PureComponent {
  static propTypes = {
    urlLogin: PropTypes.string.isRequired,
    urlLoginReminder: PropTypes.string,
    apiRequestOptions: PropTypes.object,
    loginPlaceholder: PropTypes.string,
    loginUrlVar: PropTypes.string,
    pwdPlaceholder: PropTypes.string,
    pwdUrlVar: PropTypes.string,
    buttonProps: PropTypes.object,
    errorMessageLogin: PropTypes.string,
    errorMessageLoginReminder: PropTypes.string,
    loginResponseValidation: PropTypes.func,
    handleLoginSuccess: PropTypes.func,
    handleLoginError: PropTypes.func,
    loginReminderResponseValidation: PropTypes.func,
    successMessageLoginReminder: PropTypes.string,
  }
  static defaultProps = {
    apiRequestOptions: {},
    loginPlaceholder: 'Usuario',
    loginUrlVar: 'login',
    pwdPlaceholder: 'Contraseña',
    pwdUrlVar: 'password',
    buttonProps: {},
    errorMessageLogin:
      'El usuario o password es incorrecto. Por favor, inténtalo de nuevo',
    errorMessageLoginReminder:
      'Se ha producido un error. Por favor, inténtalo de nuevo en unos minutos.',
    loginResponseValidation: data => null,
    handleLoginSuccess: data => null,
    handleLoginError: data => null,
    loginReminderResponseValidation: data => null,
    successMessageLoginReminder:
      'Te hemos enviado un correo electrónico con los pasos a seguir. ¡Muchas gracias!',
  }

  state = {
    status: 'relax',
    screen: 'login',
    login: '',
    password: '',
  }

  handleFieldChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  handleDismissErrorMessage = () => {
    this.resetState()
  }

  handleSubmitLogin = () => {
    const { urlLogin, apiRequestOptions, loginUrlVar, pwdUrlVar } = this.props
    const { login, password } = this.state
    this.setState({ status: 'authenticating' })
    this.postLogin(
      urlLogin,
      apiRequestOptions,
      loginUrlVar,
      pwdUrlVar,
      login,
      password
    )
  }

  handleForgotPassword = () => {
    this.setScreen('remind-password-form')
  }

  handleSubmitReminder = () => {
    const { urlLoginReminder, apiRequestOptions, loginUrlVar } = this.props
    const { login } = this.state
    this.setState({ status: 'sending-reminder' })
    this.postLoginReminder(
      urlLoginReminder,
      apiRequestOptions,
      loginUrlVar,
      login
    )
  }

  handleBackToLogin = () => {
    this.setScreen('login')
  }

  resetState = () => {
    this.setState({
      status: 'relax',
      login: '',
      password: '',
    })
  }

  setScreen = screen => {
    this.setState({ screen, status: 'relax' })
  }

  postLogin = (
    urlLogin,
    apiRequestOptions,
    loginUrlVar,
    pwdUrlVar,
    login,
    password
  ) => {
    axios({
      method: 'post',
      url: urlLogin,
      data: {
        [loginUrlVar]: login.trim(),
        [pwdUrlVar]: password.trim(),
      },
      ...apiRequestOptions,
    })
      .then(response => {
        if (this.props.loginResponseValidation(response)) {
          this.setState({ status: 'success-login' })
          this.props.handleLoginSuccess(response)
        } else {
          this.setState({ status: 'error-login' })
          this.props.handleLoginError(
            new Error('Credentials validation has failed.')
          )
        }
      })
      .catch(error => {
        console.error(error)
        this.setState({ status: 'error-login' })
        this.props.handleLoginError(error)
      })
  }

  postLoginReminder = (
    urlLoginReminder,
    apiRequestOptions,
    loginUrlVar,
    login
  ) => {
    axios({
      method: 'post',
      url: urlLoginReminder,
      data: {
        [loginUrlVar]: login.trim(),
      },
      ...apiRequestOptions,
    })
      .then(response => {
        if (this.props.loginReminderResponseValidation(response)) {
          this.setState({ status: 'success-login-reminder' })
          this.setScreen('remind-password-success')
        } else {
          this.setState({ status: 'error-login-reminder' })
        }
      })
      .catch(error => {
        console.error(error)
        this.setState({ status: 'error-login-reminder' })
      })
  }

  render() {
    const {
      urlLogin,
      urlLoginReminder,
      apiRequestOptions,
      loginPlaceholder,
      loginUrlVar,
      pwdPlaceholder,
      pwdUrlVar,
      buttonProps,
      errorMessageLogin,
      errorMessageLoginReminder,
      loginResponseValidation,
      handleLoginSuccess,
      handleLoginError,
      loginReminderResponseValidation,
      successMessageLoginReminder,
      ...rest
    } = this.props
    const { status, screen, login, password } = this.state
    return (
      <div id="login-form-component">
        {screen === 'login' && (
          <Form error={status === 'error-login'} {...rest}>
            <Form.Input
              icon="user"
              iconPosition="left"
              placeholder={loginPlaceholder}
              name="login"
              value={login}
              onChange={this.handleFieldChange}
            />
            <Form.Input
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder={pwdPlaceholder}
              name="password"
              value={password}
              onChange={this.handleFieldChange}
            />
            <Message
              error
              header="ERROR"
              content={errorMessageLogin}
              onDismiss={this.handleDismissErrorMessage}
            />
            <Form.Button
              fluid
              size="large"
              style={{ marginTop: '1.6em' }}
              content="Entrar"
              loading={status === 'authenticating'}
              onClick={this.handleSubmitLogin}
              {...this.props.buttonProps}
            />
            {urlLoginReminder && (
              <div style={{ textAlign: 'center' }}>
                <Button
                size='tiny'
                style={{ cursor: 'pointer' }}
                onClick={this.handleForgotPassword}
                >
                  ¿Has olvidado tu contraseña?
                </Button>
              </div>
            )}
          </Form>
        )}
        {screen === 'remind-password-form' && (
          <Form error={status === 'error-login-reminder'} {...rest}>
            <Form.Input
              icon="user"
              iconPosition="left"
              placeholder={loginPlaceholder}
              name="login"
              value={login}
              onChange={this.handleFieldChange}
            />
            <Message
              error
              header="ERROR"
              content={errorMessageLoginReminder}
              onDismiss={this.handleDismissErrorMessage}
            />
            <Form.Button
              fluid
              size="large"
              style={{ marginTop: '1.6em' }}
              content="Recordar contraseña"
              loading={status === 'sending-reminder'}
              onClick={this.handleSubmitReminder}
              {...this.props.buttonProps}
            />
            <div style={{ textAlign: 'center' }}>
                <Button
                size='tiny'
                style={{ cursor: 'pointer' }}
                onClick={this.handleBackToLogin}
                >
                  Volver a Login
                </Button>
            </div>
          </Form>
        )}
        {screen === 'remind-password-success' && (
          <Fragment>
            <Segment>
              <div style={{ textAlign: 'center' }}>
                <Icon name="check" size="huge" color="green" />
              </div>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '1em',
                  fontSize: '1.2em',
                  fontWeight: 'bold',
                }}
              >
                ¡Proceso completado!
              </div>
              <div style={{ textAlign: 'center', marginTop: '0.6em' }}>
                {successMessageLoginReminder}
              </div>
            </Segment>
            <Button
              fluid
              size="large"
              style={{ marginTop: '1.6em' }}
              content="Volver a Login"
              onClick={this.handleBackToLogin}
              {...this.props.buttonProps}
            />
          </Fragment>
        )}
      </div>
    )
  }
}

export default LoginForm
